const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Licensed Distributor Associate",
    route: "/views",
    pages: [
      {
        sectionTitle: "Clients",
        route: "/licensed-distributor-clients",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Client List",
            route: "/licensed-distributor-associate/clients",
          },
        ],
      },
      {
        sectionTitle: "Personal Folders",
        route: "/personal",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Behavioral Surveys",
            route: "/personal/survey-folder",
          },
          {
            heading: "JOB Surveys",
            route: "/personal/job-survey-folder",
          },
        ],
      },
    ],
  },
  {
    heading: "Surveys",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Behavorial Surveys",
        route: "/pages",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Sent Surveys",
            route: "/survey/sent-surveys/behavioral",
          },
          {
            heading: "Email A Survey",
            route: "/survey/emailsurvey/behavioral",
          },
          {
            heading: "Enter Survey Results",
            route: "/survey/lda-enterbehavioralsurvey",
          },
          /*{
            heading: "Standalone Kiosk Survey",
            route: "/wizard",
          },*/
        ],
      },
      {
        sectionTitle: "JOB Surveys",
        route: "/pages",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Sent Surveys",
            route: "/survey/sent-job-surveys/job",
          },
          {
            heading: "Email A Survey",
            route: "/survey/emailsurvey/job",
          },
          {
            heading: "Enter Survey Results",
            route: "/survey/lda-enterjobsurvey",
          },
        ],
      },
    ],
  },
  {
    heading: "Survey Analysis",
    route: "/crafted",
    pages: [
      {
        heading: "JOB Match To Profile",
        route: "/survey-analysis/match-job-survey",
        svgIcon: "media/icons/duotune/maps/map003.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
