const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "My Business",
    route: "/crafted",
    pages: [
      /*{
        heading: "Organization Structure",
        route: "/helpfultips",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },*/
      /*{
        heading: "Analytics",
        route: "/helpfultips",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },*/
      {
        heading: "Employees",
        route: "/client/employees",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Pending Hires",
        route: "/client/pendinghires",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Former Employees",
        route: "/client/formeremployees",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Not Hired",
        route: "/client/nothired",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Locations",
        route: "/client/client-location-list",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Behavorial Surveys",
    route: "/crafted",
    pages: [
      {
        heading: "Sent Surveys",
        route: "/survey/sent-surveys/behavioral",
        svgIcon: "media/icons/duotune/files/fil001.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "All Behavorial Surveys",
        route: "/client/all-behavioral-surveys",
        svgIcon: "media/icons/duotune/files/fil012.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Email A Survey",
        route: "/survey/client-emailsurvey/behavioral",
        svgIcon: "media/icons/duotune/communication/com002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Enter A Survey",
        route: "/survey/client-enterbehavioralsurvey",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      /*{
        heading: "Standalone Kiosk Survey",
        route: "/helpfultips",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },*/
    ],
  },
  {
    heading: "JOB Surveys",
    route: "/crafted",
    pages: [
      {
        heading: "Sent Surveys",
        route: "/survey/sent-job-surveys/job",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "All JOB Surveys",
        route: "/client/all-job-surveys",
        svgIcon: "media/icons/duotune/files/fil012.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Email A Survey",
        route: "/survey/client-emailsurvey/job",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Enter A Survey",
        route: "/survey/client-enterjobsurvey",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Survey Analysis",
    route: "/crafted",
    pages: [
      {
        heading: "JOB Match To Profile",
        route: "/survey-analysis/match-job-survey",
        svgIcon: "media/icons/duotune/maps/map003.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Support",
    route: "/crafted",
    pages: [
      {
        heading: "Contact Content Expert",
        route: "/contact-content-expert",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Contact Support",
        route: "/contact-support",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
