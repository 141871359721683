const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "User Accounts",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Users",
        route: "/pages",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Power Users",
            route: "/client/client-poweruser-list",
          },
          {
            heading: "Client Users",
            route: "/client/client-clientuser-list",
          },
        ],
      },
    ],
  },
  {
    heading: "Support",
    route: "/crafted",
    pages: [
      {
        heading: "Helpful Tips",
        route: "/helpfultips",
        role: "SuperAdmin",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
