const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Super Admin",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Users",
        route: "/pages",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Application Users",
            route: "/super-admin/applicationuserlisting",
          },
          {
            heading: "Licensed Distributors",
            route: "/super-admin/licenseddistributorlisting",
          },
        ],
      },
      {
        sectionTitle: "Setup",
        route: "/setup",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Behavioral Words",
            route: "/super-admin/behavorialwords",
          },
          {
            heading: "JOB Phrases",
            route: "/super-admin/jobphrases",
          },
        ],
      },
      {
        sectionTitle: "Clients",
        route: "/clients",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Client List",
            route: "/super-admin/clients",
          },
        ],
      },
      {
        sectionTitle: "Personal Folders",
        route: "/personal",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Behavioral Surveys",
            route: "/personal/survey-folder",
          },
          {
            heading: "JOB Surveys",
            route: "/personal/job-survey-folder",
          },
        ],
      },
    ],
  },
  {
    heading: "Surveys",
    route: "/surveys",
    pages: [
      {
        sectionTitle: "Behavorial Surveys",
        route: "/pages",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Sent Surveys",
            route: "/survey/sent-surveys/behavioral",
          },
          {
            heading: "Email A Survey",
            route: "/survey/emailsurvey/behavioral",
          },
          {
            heading: "Enter Survey Results",
            route: "/survey/enterbehavioralsurvey",
          },
          /*{
            heading: "Standalone Kiosk Survey",
            route: "/wizard",
          },*/
        ],
      },
      {
        sectionTitle: "JOB Surveys",
        route: "/pages",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Sent Surveys",
            route: "/survey/sent-job-surveys/job",
          },
          {
            heading: "Email A Survey",
            route: "/survey/emailsurvey/job",
          },
          {
            heading: "Enter Survey Results",
            route: "/survey/enterjobsurvey",
          },
        ],
      },
    ],
  },
  {
    heading: "Survey Analysis",
    route: "/surveys",
    pages: [
      {
        sectionTitle: "Survey Analysis",
        route: "/account",
        svgIcon: "media/icons/duotune/maps/map003.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "JOB Match To Profile",
            route: "/survey-analysis/match-job-survey",
          },
          {
            heading: "Survey Validity",
            route: "/survey-analysis/survey-validity-report",
          },
          /*{
            heading: "Frequency Report",
            route: "/super-admin/frequencyreport",
          },
          {
            heading: "Compare Personality to Jobs",
            route: "/crafted/account/settings",
          },
          {
            heading: "Group Analysis",
            route: "/crafted/account/settings",
          },
          {
            heading: "Master Pattern Report",
            route: "/crafted/account/settings",
          },
          {
            heading: "Profile Frequency",
            route: "/crafted/account/settings",
          },*/
        ],
      },
    ],
  },
];

export default DocMenuConfig;
